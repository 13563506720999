<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Periode"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
        <template #cell(status)="data">
          <span
            class="badge "
            :class="data.item.status == 'requested'?'bg-warning':data.item.status == 'approved'?'bg-success':'bg-danger'"
          >
            {{ data.item.status }}
          </span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      dataurl:"/vehicle-mutation?status=requested,rejected,approved",
      baseroute:"mutation",
      title:"Permintaan Mutasi Kendaraan",
      fields: [
        { key: 'destination.name', label: 'Cabang Requestor', sortable: true },
        { key: 'origin.name', label: 'Cabang Pengirim', sortable: true },
        { key: 'request_date', label: 'Tgl Permintaan', sortable: true },
        { key: 'number', label: 'No Permintaan', sortable: true },
        { key: 'vehicles_count', label: 'Qty Kendaraan', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      filter :[],
      active:null,
      date_start:"",
      date_end:""
    }
  },
  methods:{
    show(){
      // this.filter['filter[active]']=this.active
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[request_date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.date_start = ""
      this.date_end = ""
      this.filter['between[request_date]'] = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
